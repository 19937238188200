import Nav from "../components/Nav";
import * as React from 'react';
import { useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Parallax } from 'react-scroll-parallax';

function Home() {
    const currentTab = "home";
    useEffect(() => {
        AOS.init({ disable: "mobile" });
    }, [])
    return (
        <>
            <div className="home">
                <Nav Tab={currentTab} />

                <div className="home-top section" id="home">
                    <h1>

                        <div className="letter">S</div>
                        <div className="letter">N</div>
                        <div className="letter">A</div>
                        <div className="letter">K</div>
                        <div className="letter">E</div>
                        <div className="letter">S</div>
                        <div className="letter">&nbsp;</div>
                        <div className="letter">O</div>
                        <div className="letter">F</div>
                        <div className="letter">&nbsp;</div>
                        <div className="letter">S</div>
                        <div className="letter">U</div>
                        <div className="letter">I</div>


                    </h1>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSchCDbqDJHYU8eys8sddAxI47vOV4NmjHhAdHq0J4WuPb3Ddg/viewform?usp=sf_link" target="_blank" rel="noreferrer"><button className="darkbg">MINT COMING SOON</button></a>

                </div>
                <div className="project" id="project">
                    <div className="imgteam">
                        <div className="fadeBot"></div>
                        <img src="/assets/project.webp" alt="" data-aos="zoom-in" />
                    </div>
                    <div className="text-part" data-aos="fade-left" data-aos-offset="300">
                        <div className="title">
                            <h3>THE</h3>
                            <h2>STORY</h2>
                        </div>
                        <p>
                            Throughout human history, governments have risen and fallen, kingdoms have conquered and been conquered, and empires have reigned with total supremacy, only to come crashing down at the feet of a stronger foe. However, there has been one, and only one, constant.
                            <br /><br />
                            The Snakes. They have insidiously slithered their way into every major government, kingdom, empire, and social order that has ever been, stealthily and masterfully orchestrating world events, like a puppeteer commands a marionette. Where they come from, and what they wish to achieve, is unknown.
                            <br /><br />
                            For the first time in history, The Snakes are opening their castle doors to new members. Competition for places within The Society will be high, and spaces are limited. However, for the lucky few who earn their invite, treasure, affluence, and exclusive experiences are abound
                        </p>
                    </div>
                </div>
                <h3 className="carouseltitle">The Society Members</h3>
                <section class="slider-part bg-dark py-3">
                    <div class="marquee-wrapper">
                        <div class="container">
                            <div class="marquee-block">

                                <div class="marquee-inner to-left"><span>
                                    <div class="marquee-item"><img src="/assets/gallery/1.webp" alt="1" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/2.webp" alt="2" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/3.webp" alt="3" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/4.webp" alt="4" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/5.webp" alt="5" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/6.webp" alt="6" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/7.webp" alt="7" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/8.webp" alt="8" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/9.webp" alt="9" /></div>
                                    <div class="marquee-item"><img src="/assets/gallery/10.webp" alt="10" /></div>



                                </span><span>
                                        <div class="marquee-item"><img src="/assets/gallery/1.webp" alt="1" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/2.webp" alt="2" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/3.webp" alt="3" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/4.webp" alt="4" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/5.webp" alt="5" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/6.webp" alt="6" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/7.webp" alt="7" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/8.webp" alt="8" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/9.webp" alt="9" /></div>
                                        <div class="marquee-item"><img src="/assets/gallery/10.webp" alt="10" /></div>

                                    </span></div>
                            </div>

                        </div>
                    </div>

                </section>
                <div class="timeline" id="roadmap">

                    <div className="text-part">
                        <div className="title" data-aos="zoom-in">
                            <h2>ROADMAP</h2>
                            <h4>Value first. Read on.</h4>
                        </div>
                    </div>
                    <div class="timeline__event  animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-right">
                            <img src="/assets/Roadmap_1.webp" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            t-2
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>The Origins</span>

                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Masterfully crafted over the course of 3 months, with meticulous attention to detail, Snakes of SUI mark the most intricate collection on the SUI network to date. Over 200 traits custom fitted to 10 different base characters, in conjunction with a captivating lore, represent an ode to the possibilities of digital art and storytelling.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated timeline__event--type2">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_2.webp" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            t-1
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>The Expansion</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Our foremost objective prior to main-net launch is building a loyal follower base and rewarding those pledging allegiance to the Society early on. While the first 300 members to register for WL will be granted a free mint, the most dedicated members of our community shall receive honorary positions in the project, accompanied by additional free mints. As a testament to our “value first” ethos, we are giving away two safari tours in Southeast Asia, in partnership with exploreherpetology.com that will be documented on our Twitter. Those less inclined to brave encounters with some of the most awe-inspiring animals known to man, may opt to sell their NFT ticket to fellow wildlife enthusiasts.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type3">
                        <div class="timeline__event__icon" data-aos="fade-right">
                            <img src="/assets/Roadmap_3.webp" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            t:0
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>The Reveal</span>
                                </div>

                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    The Snakes have conquered the SUI network and you managed to get your hands on one. To extend their dominion, the Snakes are conceiving strategies and tools to extract maximum value for themselves and their holders. In the upcoming weeks, you can anticipate an array of developments, including: <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	The unveiling of a Sniping Tool<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Free Mint spots and alpha calls from OG projects that our team works with<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Exquisite Merchandise - we have already secured official Amazon seller status<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Establishment of an incubator for utility-driven Web3 projects, enabled by our in-house team<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Secondary marketplace listing<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Launch of the $HISS token: Prioritizing value, the token will only be released after the key value drivers in the Snake ecosystem have been established. NFT tiers (corresponding to snake species) will serve as a mechanism for token distribution, with the lower-ranked Snakes receiving one token per reward, while the rarest ones shall receive ten. Our vision is to establish $HISS tokens as widely accepted currency across partner projects in the SUI ecosystem and beyond<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;-	Oh, and we are unveiling further real-life experiences featuring extraordinary wildlife with our partners at explore Herpetology, Myrtle Beach Safari, and others

                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type1">
                        <div class="timeline__event__icon" data-aos="fade-left">
                            <img src="/assets/Roadmap_4.webp" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            t+1
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>The Future</span>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    The Snakes, ever responsive to their community's input, will develop further value-accruing tools and initiatives. Our team shall meticulously vet each suggestion and foster the ideas and concepts that we believe to hold the greatest potential of generating financial returns for our valued holders. <br />
                                    In addition, we will welcome strictly utility-driven projects to our incubator program, using in-house resources and expertise to empower visionary founding teams. Snake NFT holders shall enjoy free mint spots and other bespoke benefits.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="getfav">Friends and partners!</h2>
                <div className="partners">
                    <div className="partner">
                        <img src="/assets/partners/Davidsfeed.webp" alt="" />
                        <h3>Davidsfeed</h3>
                    </div>
                    <div className="partner">
                        <img src="/assets/partners/Mowgli from Myrtle Beach Safari.webp" alt="" />
                        <h3>Mowgli from Myrtle Beach Safari</h3>
                    </div>
                    <div className="partner">
                        <img src="/assets/partners/OG SUI projects, to be announced.webp" alt="" />
                        <h3>OG SUI projects, to be announced</h3>
                    </div>

                </div>
                <div className="partners">
                    <div className="partner">
                        <img src="/assets/partners/The team at Web3 Factory Berlin.webp" alt="" />
                        <h3>The team at Web3 Factory Berlin</h3>
                    </div>
                    <div className="partner">
                        <img src="/assets/partners/The team from Exploreherpetology.com.webp" alt="" />
                        <h3>The team from Exploreherpetology.com</h3>
                    </div>
                </div>
                <div className="team-wrap" id="team">
                    <div className="title">

                        <h2>THE <span>TEAM</span></h2>
                    </div>
                    <div className="team">

                        <div className="imgteam">
                            <div className="fadeBot"></div>
                            <img src="/assets/team.webp" alt="" data-aos="zoom-in" />
                        </div>
                        <div className="text-part" data-aos="fade-left">
                            <p>
                                Our team comprises of five Web3 consultants, digital artists and blockchain developers who have helped launched 50+ Web3 projects within the last 18 months - including highly successful ones. Before diving into the realm of blockchain and NFTs, a couple of us worked as strategy consultants for some of the largest and most reputable companies worldwide. <br /> <br />
                                Snakes of SUI is our passion project, wherein we bring together the finest aspects of a community driven by captivating lore and art, and real, tangible benefits for NFT holders.
                            </p>
                        </div>


                    </div>
                </div>
                <div className="prologue-wrap" id="launch">
                    <div className="prologue launch">
                        <div className="text-part" data-aos="fade-right">
                            <div className="title">
                                <h2>LAUNCH</h2>
                            </div>

                            <p>
                                Snakes of SUI will launch as soon as the SUI main net goes live - expect us to be among the first projects to mint. To register for WL (free mint for first 300) just drop your wallet address in the form below and follow our Twitter and Discord for regular updates.                            </p>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSchCDbqDJHYU8eys8sddAxI47vOV4NmjHhAdHq0J4WuPb3Ddg/viewform?usp=sf_link" target="_blank" rel="noreferrer"><button>Register for whitelist</button></a>
                        </div>
                        <img src="/assets/launch.gif" alt="" data-aos="fade-left" />


                    </div>
                </div>


                <div className="faq" id="faq">
                    <div className="imgteam">
                        <div className="fadeBot"></div>
                        <img src="/assets/faq.webp" alt="" data-aos="zoom-in" />
                    </div>
                    <div className="text-part faqq">
                        <div className="title">
                            <h3>FREQUENTLY ASKED</h3>
                            <h2>QUESTIONS</h2>
                        </div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>1.</span> When is the minting date?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Since the Sui main-net has yet to go live, we are unable to establish exact dates for the sale and presale. Not to worry though, simply follow our Twitter and Discord and be the first to find out.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>2.</span> Where can I mint?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    We are currently evaluating whether a mint through our friends at one of the SUI launchpads or our own minting engine will be most beneficial. Stay tuned for an update.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>3.</span> Which wallet should I use?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    There are several wallets at your disposal, however we recommend the SUI wallet.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>4.</span> How high will the minting price be?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Value first. Loyal community members will be granted a free mint, Wave 1 public mint will be low-priced and Wave 2 public mint will be reasonably priced.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>5.</span> How long until I get my merch?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Holders in the US and European countries will receive merch within 1-5 days after ordering, shipping to other territories will take up to 2 weeks.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="discord-banner-wrap">
                    <div className="discord-banner">
                        <div className="text-part">
                            <div className="title">
                                <h3>JOIN US ON</h3>
                                <h2>DISCORD</h2>
                            </div>

                            <a href="https://discord.gg/nzYjUT4T" rel="noreferrer" target="_blank"><button>Connect with us</button></a>
                        </div>
                        <img src="/assets/discord.webp" alt="" data-aos="zoom-in" />
                    </div>
                </div>
                <footer>
                    <Link className='mainlogowrap' to="/"><img src="/logo512.webp" className='mainlogo' alt="" />Copyright ©2023 SUI Snakes. All Rights Reserved</Link>
                    <div className=' social-icons'>
                        <a href="https://twitter.com/SnakesOfSui" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/nzYjUT4T" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>

                    </div>
                </footer>
            </div>
        </>
    )
}
export default Home;